// Font Family
$muli: "Muli";
$body-font: $muli;
$heading-font: $muli;
$link-font: "Rubik", sans-serif;

// Icon Font
$fontAwesome5Brands: "Font Awesome 5 Brands";
$fontAwesome5Pro: "Font Awesome 5 Pro";

// Colors ---------------
// Border Color
$border-color: #f3f3f3;

// Default Transition
$transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

// Socail Color
$android: #7ac157;
$apple: #b8b8b8;
$behance: #1869ff;
$codepen: $black;
$dribbble: #ea4c8a;
$dropbox: #007ee5;
$evernote: #78d525;
$facebook: #4867aa;
$github: #313131;
$google-drive: #1da462;
$google-earth: #4285f4;
$google-glass: #ea4335;
$google-maps: #5083c3;
$google-play: #01b9fd;
$google-plus: #dd5144;
$google: #4285f4;
$instagram: #b23a94;
$css3: #0277bd;
$html5: #e44d26;
$javascript: #f9dc3d;
$python: #0c9dbf;
$lastfm: #e31b23;
$linkedin: #007bb6;
$paypal: #002f86;
$pinterest: #bd081b;
$pocket: #ef3e56;
$polymer: #f87292;
$rss: #f99c3a;
$share: #2c9cff;
$stackoverflow: #f38024;
$steam: #15497b;
$twitter: #1da1f2;
$vk: #5181b8;
$wikipedia: #e9e9e9;
$windows: #0078d6;
$s500px: $black;
$s8tracks: #122d4b;
$amazon: #f79b34;
$blogger: #f06a35;
$delicious: #0000fe;
$disqus: #2e9efe;
$flattr: #7ab831;
$flickr: #fe0084;
$odnoklassniki: #f58220;
$outlook: #0072c6;
$playstation: #07418e;
$reddit: #ff4500;
$skype: #00a9f0;
$slideshare: #0077b5;
$soundcloud: #fe4900;
$tumblr: #36465d;
$twitch: #6441a4;
$vimeo: #1ab7ea;
$whatsapp: #189d0e;
$xbox: #107c0f;
$yahoo: #4101af;
$youtube: #fe0000;

// Responsive Variables
$extraBig-device: "only screen and (min-width: 1600px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";
