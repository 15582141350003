@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli.woff2") format("woff2"), url("../../fonts/muli/Muli.woff") format("woff"), url("../../fonts/muli/Muli.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-Bold.woff2") format("woff2"), url("../../fonts/muli/Muli-Bold.woff") format("woff"), url("../../fonts/muli/Muli-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-Light.woff2") format("woff2"), url("../../fonts/muli/Muli-Light.woff") format("woff"), url("../../fonts/muli/Muli-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-ExtraLightItalic.woff2") format("woff2"), url("../../fonts/muli/Muli-ExtraLightItalic.woff") format("woff"), url("../../fonts/muli/Muli-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Signarita Chloe";
  src: url("../../fonts/fontAwesomePro/Signarita-Chloe.ttf") format("truetype");
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-Semi-BoldItalic.woff2") format("woff2"), url("../../fonts/muli/Muli-Semi-BoldItalic.woff") format("woff"), url("../../fonts/muli/Muli-Semi-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-BoldItalic.woff2") format("woff2"), url("../../fonts/muli/Muli-BoldItalic.woff") format("woff"), url("../../fonts/muli/Muli-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-Italic.woff2") format("woff2"), url("../../fonts/muli/Muli-Italic.woff") format("woff"), url("../../fonts/muli/Muli-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-ExtraLight.woff2") format("woff2"), url("../../fonts/muli/Muli-ExtraLight.woff") format("woff"), url("../../fonts/muli/Muli-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-LightItalic.woff2") format("woff2"), url("../../fonts/muli/Muli-LightItalic.woff") format("woff"), url("../../fonts/muli/Muli-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Muli";
  src: url("../../fonts/muli/Muli-SemiBold.woff2") format("woff2"), url("../../fonts/muli/Muli-SemiBold.woff") format("woff"), url("../../fonts/muli/Muli-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
