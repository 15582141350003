.titlelogo {
  color: white !important;
  font-family: "Signarita Chloe";
  font-size: 50px;

  @media #{$small-mobile} {
    color: white !important;
    font-size: 45px;
    font-family: "Signarita Chloe";
  }
  @media #{$extra-small-mobile} {
    color: white !important;
    font-size: 40px;
    font-family: "Signarita Chloe";
  }
}
