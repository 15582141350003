/* 12.01 About CSS */
/*== >Pricing table  ===*/

.pricingtable-inner {
  text-align: center;
  background: #fff;
}
.pricingtable-price {
  padding: 20px 20px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}
.pricingtable-bx {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
}
.pricingtable-type {
  font-size: 20px;
  text-transform: uppercase;
  font-size: 16px;
}
.pricingtable-type:before {
  content: "/";
  margin-right: 3px;
}
.pricingtable-title {
  background-color: #fff;
  padding: 20px;
  text-transform: capitalize;
}
.pricingtable-title * {
  margin: 0;
  color: #fff;
  font-weight: 800;
}
.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #e9e9e9;
}
.pricingtable-features li {
  padding: 12px;
  border-bottom: 1px solid #e9e9e9;
}
.pricingtable-features li i {
  margin: 0 3px;
}
.pricingtable-features li:nth-child(even) {
  background-color: #fff;
}
.pricingtable-features li:last-child {
  border-bottom: none;
}
.pricingtable-footer {
  margin-top: -1px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}
.pricingtable-highlight {
  margin: -20px 0;
  -webkit-box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 99;
}
.pricingtable-highlight .pricingtable-price {
  padding: 30px 20px;
}
.pricingtable-highlight .pricingtable-footer {
  padding: 30px 20px;
}
.no-col-gap .pricingtable-wrapper {
  margin-left: -1px;
}
/* pricingtable Style 1 */
.pricingtable-wrapper.style1,
.pricingtable-wrapper.style2 {
  box-shadow: 0 0 10px 10px rgba(216, 214, 214, 0.3);
  border-radius: 5px;
  padding: 30px 0 40px;
  background-color: #fff;
}
.pricingtable-wrapper.style1 .pricingtable-icon i {
  color: #000000;
  font-size: 48px;
  line-height: 60px;
}
.pricingtable-wrapper.style1 .pricingtable-price,
.pricingtable-wrapper.style2 .pricingtable-price {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-width: 0 0 1px;
  margin: 0 70px 30px;
}
.pricingtable-wrapper.style1 .pricingtable-bx {
  font-size: 42px;
  font-weight: 700;
}
.pricingtable-wrapper.style1 .pricingtable-features,
.pricingtable-wrapper.style2 .pricingtable-features {
  border: 0;
}
.pricingtable-wrapper.style1 .pricingtable-features li,
.pricingtable-wrapper.style2 .pricingtable-features li {
  background: #ffffff none repeat scroll 0 0;
  border: 0 none;
  color: rgba(36, 36, 37, 0.5);
  font-family: roboto;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-size: 16px;
}
.pricingtable-wrapper.style1 .pricingtable-features,
.pricingtable-wrapper.style2 .pricingtable-features {
  width: 100%;
  margin: 0 0 30px;
}
.pricingtable-wrapper.style1 .pricingtable-features li i {
  border: 2px solid;
  border-radius: 20px;
  display: block;
  float: right;
  font-size: 11px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  width: 20px;
}
.pricingtable-wrapper.style1 .button-md {
  font-weight: 400;
  padding: 12px 35px;
}
.pricingtable-wrapper.style1 .pricingtable-type {
  text-transform: capitalize;
}
.pricingtable-wrapper.style1.active {
  transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  -moz-transform: translateY(-25px);
  -webkit-transform: translateY(-25px);
  -o-transform: translateY(-25px);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  z-index: 2;
  border: 0;
  padding: 50px 20px;
  margin: 0 -5px;
  position: relative;
  background: #fff;
}
.pricingtable-wrapper.style2 {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 0 40px;
  border: 1px solid #e9e9e9;
}
.pricingtable-wrapper.style2 .pricingtable-price {
  padding: 0;
}
.pricingtable-wrapper.style2 .pricingtable-bx {
  color: #242425;
  display: inline-block;
  font-size: 24px;
  margin-top: 10px;
  padding: 10px;
  margin-bottom: 20px;
  font-family: "Work Sans", sans-serif;
}
.pricingtable-wrapper.style2 .pricingtable-bx span {
  font-size: 22px;
}
.pricingtable-wrapper.style2.active {
  margin: -30px -10px 0;
  padding: 60px 30px;
  position: relative;
  z-index: 1;
}
.pricingtable-wrapper.style2.active .pricingtable-bx {
  color: #fff;
}
.about-image-area{
    position: relative;
    z-index: 9;
    height: 100%;
    margin-right: 45px;
    &.right-0{
        .about-image{
            right: 0;
        }
    }
    .about-image{
        position: relative;
        top: 0;
        right: 45px;
        float: right;
        z-index: 9;
        &.right-n50{
            right: -50px !important;
            // Responsive
            @media #{$laptop-device}{
                right: 0 !important;
            }
            @media #{$desktop-device}{
                right: 0 !important;
            }
            @media #{$tablet-device}{
                right: 0 !important;
            }
            @media #{$large-mobile}{
                right: 0 !important;
            }
        }
        // Responsive
        @media #{$desktop-device}{
            right: 0;
        }
        @media #{$large-mobile}{
            right: 0;
        }
        @media #{$extra-small-mobile}{
            right: 0;
            width: 250px;
        }
        &:nth-child(2){
            position: absolute;
            top: 70px;
            left: 0;
            z-index: 8;
            float: left;
            text-align: left;
        }
        img{
            border-radius: 5px;
            box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
            transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
        }
        &:hover {
            & img {
                box-shadow: 0 0.5rem 1.2rem rgba(0, 0, 0, 0.1), 0 2rem 3rem rgba(0, 0, 0, 0.15);
            }
        }
    }

    & .shape {
        position: absolute;
        z-index: 1;

        &-1 {
            right: 0;
            top: 50px;
            z-index: -1;
        }
    }

    // Responsive
    @media #{$desktop-device}{
        margin-right: 0;
        & .shape {
            position: absolute;
            z-index: -1;
    
            &-1 {
                right: 0;
                top: 50px;
                z-index: -1;
                span{
                    width: 200px;
                }
                
            }
        }
    }
    @media #{$tablet-device}{
        margin-right: 0;
        & .shape {
            position: absolute;
            z-index: -1;
    
            &-1 {
                right: 0;
                top: 50px;
                z-index: -1;
                span{
                    width: 200px;
                }
            }
        }
    }

    @media #{$large-mobile}{
        margin-right: 0;
        & .shape {
            position: absolute;
            z-index: -1;
    
            &-1 {
                right: 0;
                top: 50px;
                z-index: -1;
                span{
                    width: 200px;
                }
            }
        }
    }
}

.about-content-area{
    p{
        max-width: 420px;
        margin-bottom: 0;
        // Responsive
        @media #{$tablet-device}{
            max-width: 100%;
        }
        @media #{$large-mobile}{
            max-width: 100%;
        }
    }
    // Responsive
    @media #{$tablet-device}{
        margin-top: 150px;
    }
    @media #{$large-mobile}{
        margin-top: 150px;
    }
}

.about-funfact{
    .number{
        font-size: 41px;
        line-height: 1;
        color: $primary;
        margin-bottom: 15px;
    }
    .text{
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 15px;
    }
}

.about-shape-animation{
    position: relative;

    & .shape {
        position: absolute;
        z-index: 1;

        &-1 {
            top: auto;
            bottom: 0px;
            right: -35px;
            z-index: -1;
        }
    }

    // Responsive
    @media #{$laptop-device}{
        & .shape {
    
            &-1 {
                bottom: -60px;
                right: 0px;
                z-index: -1;
            }
        }
    }
    @media #{$desktop-device}{
        & .shape {
    
            &-1 {
                bottom: -60px;
                right: 0px;
                z-index: -1;
            }
        }
    }
    @media #{$tablet-device}{
        & .shape {
    
            &-1 {
                bottom: -60px;
                right: 0px;
                z-index: -1;
            }
        }
    }
    @media #{$large-mobile}{
        & .shape {
    
            &-1 {
                bottom: -60px;
                right: 0px;
                z-index: -1;
            }
        }
    }
}

.about-content-full{
    .title{
        font-size: 42px;
        font-weight: 600;
        margin-bottom: 25px;
        // Responsive
        @media #{$tablet-device}{
            font-size: 26px;
            line-height: 32px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
            line-height: 30px;
        }
    }
    p{
        margin-bottom: 20px;
    }
}
